<template>

      <v-fab-transition>
              <v-btn 
                class     = "fabButton useFont"
                color     = "pink"
                fab
                dark
                large
                fixed
                bottom
                right
                @click.stop="buttonAction()"
              >{{buttonCount}}
              </v-btn>
      </v-fab-transition>
  
</template>

<script>

import { store }  from '@/store/store'

export default {  

  name        : 'plFabButton',
  props       : ["fabView"],
 
  computed    : {
                       
    
                disableButton() {

                switch ( this.fabView ) {
                        case 'home':
                              return ( this.store.shortList.length == 0 ) ? true : false;
                        case 'shortlist':
                              return(  this.store.selected.length == 0 ) ? true : false;
                        default:
                             return 0;
                      }
                },

                buttonCount() {

                      switch ( this.fabView ) {
                        case 'home':
                             return( this.store.shortList.length );
                        case 'shortlist':
                             return( this.store.selected.length );
                        default:
                             return 0;
                      }
                },
 
  },
  

  methods     : {
    
    buttonAction() {

                      if (this.buttonCount == 0 )
                          return;

                      switch ( this.fabView ) {
                        case 'home':
                              this.$router.push({name: 'shortlist' })
                              this.store.bottomNav ='shortlist';
                              break;
                        case 'shortlist':
                              this.$router.push({name: 'enquiry' })
                              this.store.bottomNav ='enquiry';
                              break;
                        default:
                             return;
                      }
    }

  },
  
  data() {
    return {
        store,
     };
  },

}

</script>

<style scoped>
.fabButton{
   bottom: 70px !important;
   font-size: 1.875rem !important;
}
</style>