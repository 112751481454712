<template>

      <v-dialog   v-model    = "store.showAboutDialog"
                  transition = "dialog-top-transition"
      >

      <v-card elevation="16">
      
            <v-card-title class="useFont" color='#7D5ac7'>Personnel Leasing</v-card-title>
            <v-card-text  class="useFont">
                <span>Version:        {{ appVersion     }}</span><br/>
            <v-divider></v-divider>
            <br/>
              
            <span>Ownership: <a href= 'https://www.personnelleasing.co.uk'        target="_blank">Personnel Leasing Ltd</a></span><br/>
            <span>Telephone: 03332 225 409</span><br/>
            <span>Terms:     <a href= 'https://www.personnelleasing.co.uk/terms/' target="_blank">EUA</a></span><br/>
            </v-card-text>
          
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                  color='#7D5ac7'
                  text
                  @click.stop ="store.showAboutDialog = false"
              >
              <span class="useFont"><strong>Close</strong></span>
              </v-btn>
            </v-card-actions>

      </v-card>
            
      </v-dialog>

</template>

<script>

    import { store }          from '../../store/store'
    import { apiResource }    from '../../store/apiResource'

    export default {

      name: 'aboutDialog',

      computed    : {

            appVersion() {
                  return ( apiResource.app.version ) ? apiResource.app.version :  null; 
            },

            personnelCount() {
                  return ( store.personnelArray ) ? store.personnelArray.count :  0;
            },

      },

      
      methods: {},
      
      data () {
        return {  
            store,
        }
      },
  }

</script>