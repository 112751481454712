<template>

    <v-card outlined class="vcardWhite" color="transparent">
   
       <v-card-title class="d-flex justify-center">
          <span class = "useFont" >Selected Candidates</span>
       </v-card-title>
   
       <v-card-subtitle class="d-flex justify-center">
          <span class = "useFont" >Submit your information and we will be in touch to assist in placement</span>
       </v-card-subtitle>
      
       <v-card-text>
        
        <v-form @submit.prevent="submitEnquiryClicked" ref="enquiryForm" v-model="validForm">
          
          <v-text-field
            v-model = "enquiry.contactName"
            autofocus
            label   = "Contact Name"
            :rules  ="[rules.required]"
            class = "useFont"
            outlined 
            color           ="#b2a5ce" 
            background-color="#fafafa"
          ></v-text-field>

          <v-text-field
            v-model = "enquiry.contactEmail"
            label   = "Email Address"
            :rules  = "[rules.required]"
            type    = "email"
            class   = "useFont"
            outlined 
            color           ="#b2a5ce" 
            background-color="#fafafa"
          ></v-text-field>

          <v-text-field
            v-model = "enquiry.contactPhone"
            label   = "Phone Number"
            :rules="[rules.required]"
            class = "useFont"
            outlined 
            color           ="#b2a5ce" 
            background-color="#fafafa"
          ></v-text-field>

          <v-text-field 
            v-model = "enquiry.enquiryMessage"
            label   = "Message"
            class = "useFont"
            outlined 
            color           ="#b2a5ce" 
            background-color="#fafafa"
          ></v-text-field>

          <v-card-actions>
              
                <v-btn 
                        type  = "submit"
                        class = "actionButton useFont fontSize16 text-none"
                        block
                        :disabled = "store.enquiry.sending"
                         >Submit Enquiry
                         <v-progress-circular v-if="store.enquiry.sending"
                                              indeterminate
                                              color="#b2a5ce"
                                              class="ml-2"
                                              inline>
                         </v-progress-circular>
                </v-btn>


          </v-card-actions>
            
        </v-form>

       </v-card-text>

    </v-card>
  
</template>

<script>


import axios            from "axios";
import { store }        from  '@/store/store'
import { apiResource }  from  '@/store/apiResource'


export default {  

  name        : 'plEnquiry',
  computed    : {},

  mounted() {
    this.resetValidation();
  },

  methods     : {


      submitEnquiryClicked() { 

            if( this.$refs.enquiryForm.validate() == false) 
                  return;
           
            this.submitEnquiry();
   
      },
    

      submitEnquiry : async function(){
    
            const  url            =  apiResource.getResourceUrl("enquiry");
            var    buffer         =  this.enquiry;
            
            buffer['enquiryCandidates']   =  this.store.selected;
            buffer['enquiryDate']         =  new Date().toLocaleDateString("en-GB");

            this.store.enquiry.showDialog          = true;
            this.store.enquiry.processing          = true;
             
            axios.post( url, buffer )
            .then( response => {

                   store.enquiry.processing           = false;
                   store.enquiry.emailConfirmation    = response.data;
 
            }).catch( (error) => { 

                
                const errorMessage            =   `${error.response.data.message} Status = ${error.response.status}`;

                this.store.errorSnackBar.color   = "error";
                this.store.errorSnackBar.text    = errorMessage;
                this.store.errorSnackBar.display = true;
      
                console.log( errorMessage );
           
                store.enquiry.processing      = false;
                store.enquiry.errorMessage    = errorMessage;

            });
     
      },

      reset () {
          this.$refs.enquiryForm.reset()
      },
        
      resetValidation () {
          this.$refs.enquiryForm.resetValidation()
      },

      enquiryTransmitted() {
             this.store.shortList = [];
             this.store.selected  = [];
             this.$router.push({ name : "home"});
      },

  },
  
  data() {
    return {
        store,
        validForm       : false,
        sendingEnquiry  : false,

        rules: {
          required: value => !!value || 'Required',
        },
     
        enquiry       : {
                contactName     : '',
                contactEmail    : '',
                contactPhone    : '',
                enquiryMessage  : '',
        }
     };
  },

}

</script>

<style scoped>

.actionButton{
  background-color: black !important;
  color: #FFFFFF !important;
}

.vcardWhite{
  background: white !important;
}


</style>