import { reactive } from 'vue'

export const store = reactive({


    bottomNav                              : undefined,
    enquiry                                : {
                                                emailConfirmation : null,
                                                errorMessage      : null,
                                                processing        : false,  
                                                showDialog        : false,
                                             },
    
    showAboutDialog                     :  false,
    screenSize                          :  { height : 0, width : 0},
    appTitle                            :  { title: "Personnel Leasing"},
      
    shortList                           :  [],
    selected                            :  [],
    candidates                          :  [],

    filter                             :  {
                                           skill      : '',
                                           jobTitle   : '',
                                           location   : '',
                                           service    : '',
                                       },
   errorSnackBar                       : {
                                             color   : "error",
                                             display : false,
                                             text    : "",
                                       }                   

                                           
})