
export const apiResource = {

    localDev                            :   false,
    app                                 :   { version : "1.0.23" },
    localUrlbase                        :   "http://localhost",
    liveUrlbase                         :   "https://api.personnelleasing.co.uk",
    endpoint                            :   {   
                                                "enquiry"                 : "/api/enquiry",
                                                "shortlist"               : "/api/shortlist",
                                                "candidates"              : "/api/candidates",
                                                "shortlistAll"            : "/api/shortlistAll",
                                            },
                        
    getResourceUrl  : function( resource ) {
        
        if (this.localDev) {
              return (  this.localUrlbase + this.endpoint[ resource] )
        } else {
              return (  this.liveUrlbase + this.endpoint[ resource] )
        }
    }
   
}