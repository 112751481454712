
import Vue from 'vue'

import VueRouter              from 'vue-router'

import home                   from '../views/Home.vue'
import shortlist              from '../views/Shortlist.vue'
import enquiry                from '../views/Enquiry.vue'

Vue.use(VueRouter)

const routes = [
  
  {
      path       : '/',
      name       : 'home',
      component  :  home,
  }, {
      path       : '/shortlist',
      name       : 'shortlist',
      component  :  shortlist,
  }, {
      path       : '/enquiry',
      name       : 'enquiry',
      component  :  enquiry,
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


export default router
