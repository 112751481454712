<template>

  <div>
    
    <plCounter             title="Selected Candidates" name="selected"/>
    <plEnquiry            />

  </div>

</template>

<script>

import { store }                from '../store/store'
import plCounter                from '@/components/Header/plCounter.vue';
import plEnquiry                from '@/components/plEnquiry.vue';

export default {  

name        : 'Enquiry',
components  : { plCounter, plEnquiry},
computed    : {},
methods     : {},


data() {
  
  return {
      store,
   };
},

}
</script>