<template>

      <div class="plHeaderDiv">
            <a href="https://www.personnelleasing.co.uk" target="_blank">
                <v-img class="plHeaderLogo"
                       contain
                       width="75%"
                       max-width="350"
                       :src="require('@/assets/images/plHeader.png')">
                </v-img>
            </a>
      </div>



</template>

<script>
       
    import { store }      from '@/store/store'

    export default {
      name    : 'plHeader',
      data () {
        return {  
            store,
        }
      },
      computed    : {},
      methods     : {}

    }

</script>

<style scoped>
 .plHeaderDiv{
     background: linear-gradient(#b2a5ce,#9867FF);
 }
 
</style>