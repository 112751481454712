<template>

      <div>
        <plCounter  title="Active Candidates" name="personnel"/>
        <plHome    />
        <plFabButton v-if="false" fabView="home"  />
      </div>
  
</template>

<script>

import { store }          from '../store/store'
import plCounter          from '@/components/Header/plCounter.vue';
import plFabButton        from '@/components/FabButton/plFabButton.vue';
import plHome             from '@/components/plHome.vue';


export default {  
  
    name        : 'Home',
    components  : { plCounter, plHome, plFabButton},
    computed    : {},
    mounted () {},
    methods     : {},
    data() {
      
      return {
          store,
       };
    },

}
</script>